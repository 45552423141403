import type { App, Plugin, Route, View } from "@pimo/pimo-app-builder";
import {
  DonutVerticalChartCard,
  GridLayout,
  GridLayoutProps,
  LargeTextCard,
  ProgressBarGaugeCard,
  SectionHeader,
  SectionHeaderWithMultipleBadges,
} from "@pimo/pimo-components";
import { ActivityStatus, DIMENSIONS, Maturity } from "dlp-types";
import {
  capitalize,
  DLP_COLORS,
  formatDate,
  getTextAndColorForMaturityCardBasedOnMaturityLevel,
} from "dlp-utils";

import { DLPAppState } from "../app";
import { ReportTitleCard } from "../components/report-title-card/report-title-card";
import { APP_ROUTES, latestCompletionDate } from "../constants";
import {
  fetchDashboardCalculations,
  fetchOeProjectOverview,
  fetchOEProjects,
} from "../helpers/dlp-fetch-helper";
import { isUserAdmin } from "../helpers/is-user-admin";

export class DashboardPlugin implements Plugin<DLPAppState, DLPAppState> {
  route?: Route;

  onRegister(app: App<DLPAppState>): void {
    const dashboardView = app.createView({
      name: "dashboard",
      layout: new GridLayout(),
    });

    this.buildDashboardView(dashboardView);

    this.route = app.createRoute({
      path: APP_ROUTES.dashboard,
      view: dashboardView,
    });
    this.route.on("load", async () => {
      const [oeProjects, dashboard, oeProjectOverview] = await Promise.all([
        fetchOEProjects(),
        fetchDashboardCalculations(),
        fetchOeProjectOverview(),
      ]);

      const state: DLPAppState = app.getAppState();
      app.setAppState({
        ...state,
        dashboard,
        oeProjects,
        oeProjectOverview,
        filterDialogData: {
          projectsFilter: [],
          overdueFilter: [],
          govLeadFilter: [],
          atRiskFilter: [],
          updateStatusFilter: [],
          maturityFilter: [],
          searchQueryFilter: "",
          dueYearFilter: [],
          dueQuarterFilter: [],
          regionFilter: [],
          localFilter: [],
        },
      });

      // if route is loaded and user is not admin, redirect them to the first OE report
      if (
        !isUserAdmin(app.getAppState().userProfile) &&
        app.getAppState().userProfile?.regionsUserHasAccessTo?.length === 0
      ) {
        if (oeProjects.length > 0) {
          app.navigate(`/reports/${oeProjects?.[0]?.id ?? 0}/dashboard`);
        } else {
          app.navigate(`/no-data`);
        }
      }
    });
  }

  buildDashboardView(view: View<DLPAppState, GridLayoutProps>) {
    const title = view.addComponent({
      component: ReportTitleCard,
      layoutProps: {
        xs: 12,
      },
    });

    title.mapState((state) => {
      const { name = "", reportingDate = "" } = state.program ?? {};
      const date = formatDate(reportingDate);

      return {
        title: [
          "Group Dashboard",
          [name, date && `(as of ${date})`].filter(Boolean).join(" "),
        ].join(" - "),
        endDate: formatDate(new Date(new Date().getFullYear(), 11, 32)),
      };
    });

    const overallMaturityTitle = view.addComponent({
      component: SectionHeaderWithMultipleBadges,
      layoutProps: {
        xs: 12,
      },
    });

    overallMaturityTitle.mapState((state) => {
      return {
        badges: [
          {
            title: `Overall Maturity:`,
            badgeTextContent: ` ${
              state.dashboard?.maturity as unknown as Maturity
            } `,
            ...getTextAndColorForMaturityCardBasedOnMaturityLevel(
              (state.dashboard?.maturity as unknown as Maturity) ?? 0
            ).colorScheme,
          },
          {
            title: `Target Date Maturity Level 1`,
            badgeTextContent: latestCompletionDate.priority1,
            textColor: "black",
            chipColor: DLP_COLORS.babyBlue,
            textProps: { sx: { fontWeight: 400 } },
          },
          {
            title: `Target Date Maturity Level 2`,
            badgeTextContent: latestCompletionDate.priority2,
            textColor: "black",
            chipColor: DLP_COLORS.babyBlue,
            textProps: { sx: { fontWeight: 400 } },
          },
          {
            title: `Target Date Maturity Level 3`,
            badgeTextContent: latestCompletionDate.priority3,
            textColor: "black",
            chipColor: DLP_COLORS.babyBlue,
            textProps: { sx: { fontWeight: 400 } },
          },
        ],
        sx: {
          "@media print": {
            pageBreakBefore: "avoid",
          },
        },
        chipColor:
          getTextAndColorForMaturityCardBasedOnMaturityLevel(
            state.dashboard?.maturity as unknown as Maturity
          )?.colorScheme.chipColor ?? "",
        textColor:
          getTextAndColorForMaturityCardBasedOnMaturityLevel(
            state.dashboard?.maturity as unknown as Maturity
          )?.colorScheme.textColor ?? "",
      };
    });

    const maturityOfOEsDonutCard = view.addComponent({
      component: DonutVerticalChartCard,
      layoutProps: {
        xs: 3,
      },
    });

    maturityOfOEsDonutCard.mapState((state) => {
      const numberOfOEsWithAverageMaturityZero =
        state?.oeProjects?.filter((oe) => oe._calculated?.maturity === 0)
          .length ?? 0;

      const numberOfOEsWithAverageMaturityOne =
        state?.oeProjects?.filter((oe) => oe._calculated?.maturity === 1)
          .length ?? 0;

      const numberOfOEsWithAverageMaturityTwo =
        state?.oeProjects?.filter((oe) => oe._calculated?.maturity === 2)
          .length ?? 0;

      const numberOfOEsWithAverageMaturityThree =
        state?.oeProjects?.filter((oe) => oe._calculated?.maturity === 3)
          .length ?? 0;

      return {
        cardProps: {
          sx: {
            flex: 1,
          },
        },
        title: `# of OEs per Maturity Level (total: ${
          state.oeProjects?.length ?? 0
        })`,
        footerText: "*excluding AZ Ukraine and PIMCO",
        series: [
          numberOfOEsWithAverageMaturityZero,
          numberOfOEsWithAverageMaturityOne,
          numberOfOEsWithAverageMaturityTwo,
          numberOfOEsWithAverageMaturityThree,
        ],
        fallbackText: "No activities for this dimension specified.",
        backgroundColor: Object.values(DLP_COLORS.maturityLevels).map(
          (maturityLevel) => maturityLevel.chipColor
        ),

        labels: ["Maturity 0", "Maturity 1", "Maturity 2", "Maturity 3"],
        legendPosition: "left",
        toolTipEnabled: true,

        toolTipLabels: ["Maturity 0", "Maturity 1", "Maturity 2", "Maturity 3"],
      };
    });

    const activitiesMaturityOneStatusCard = view.addComponent({
      component: DonutVerticalChartCard,
      layoutProps: {
        xs: 3,
      },
    });

    activitiesMaturityOneStatusCard.mapState((state) => {
      const numberOfActivitiesPerStatus = state.dashboard
        ?.activitiesPerStatusPerMaturity?.["maturity 1"] ?? {
        "at risk": 0,
        "not started": 0,
        "on track": 0,
        completed: 0,
        "not applicable": 0,
      };

      const statusesToDisplay: ActivityStatus[] = [
        "not applicable",
        "not started",
        "at risk",
        "on track",
        "completed",
      ];
      const totalActivities = statusesToDisplay.reduce(
        (acc, curr) => acc + numberOfActivitiesPerStatus[curr],
        0
      );
      return {
        cardProps: {
          sx: {
            flex: 1,
          },
        },
        title: `Activities Maturity 1 (total: ${totalActivities})`,
        series: statusesToDisplay.map(
          (status) => numberOfActivitiesPerStatus[status]
        ),
        fallbackText:
          "No activities of maturity level 1 for this dimension specified.",
        backgroundColor: [
          "#eeeeee",
          "#a3d4eb",
          "#FAB600",
          "#00C853",
          "#003781",
        ],
        legendIcons: ["", "", "", "", `checkmark-squared-outlined.svg`],
        legendPosition: "left",
        labels: statusesToDisplay.map((status) => capitalize(status)),
        toolTipLabels: statusesToDisplay.map((status) => capitalize(status)),
        toolTipEnabled: true,
      };
    });

    const activitiesMaturityTwoStatusCard = view.addComponent({
      component: DonutVerticalChartCard,
      layoutProps: {
        xs: 3,
      },
    });

    activitiesMaturityTwoStatusCard.mapState((state) => {
      const numberOfActivitiesPerStatus = state.dashboard
        ?.activitiesPerStatusPerMaturity?.["maturity 2"] ?? {
        "at risk": 0,
        "not started": 0,
        "on track": 0,
        completed: 0,
        "not applicable": 0,
      };

      const statusesToDisplay: ActivityStatus[] = [
        "not applicable",
        "not started",
        "at risk",
        "on track",
        "completed",
      ];
      const totalActivities = statusesToDisplay.reduce(
        (acc, curr) => acc + numberOfActivitiesPerStatus[curr],
        0
      );

      return {
        cardProps: {
          sx: {
            flex: 1,
          },
        },
        title: `Activities Maturity 2 (total: ${totalActivities})`,
        series: statusesToDisplay.map(
          (status) => numberOfActivitiesPerStatus[status]
        ),
        backgroundColor: [
          "#eeeeee",
          "#a3d4eb",
          "#FAB600",
          "#00C853",
          "#003781",
        ],
        legendIcons: ["", "", "", "", `checkmark-squared-outlined.svg`],
        legendPosition: "left",
        labels: statusesToDisplay.map((status) => capitalize(status)),
        toolTipLabels: statusesToDisplay.map((status) => capitalize(status)),
        toolTipEnabled: true,
      };
    });

    const activitiesMaturityThreeStatusCard = view.addComponent({
      component: DonutVerticalChartCard,
      layoutProps: {
        xs: 3,
      },
    });

    activitiesMaturityThreeStatusCard.mapState((state) => {
      const numberOfActivitiesPerStatus = state.dashboard
        ?.activitiesPerStatusPerMaturity?.["maturity 3"] ?? {
        "at risk": 0,
        "not started": 0,
        "on track": 0,
        completed: 0,
        "not applicable": 0,
      };

      const statusesToDisplay: ActivityStatus[] = [
        "not applicable",
        "not started",
        "at risk",
        "on track",
        "completed",
      ];
      const totalActivities = statusesToDisplay.reduce(
        (acc, curr) => acc + numberOfActivitiesPerStatus[curr],
        0
      );

      return {
        cardProps: {
          sx: {
            flex: 1,
          },
        },
        title: `Activities Maturity 3 (total: ${totalActivities})`,
        series: statusesToDisplay.map(
          (status) => numberOfActivitiesPerStatus[status]
        ),
        backgroundColor: [
          "#eeeeee",
          "#a3d4eb",
          "#FAB600",
          "#00C853",
          "#003781",
        ],
        legendIcons: ["", "", "", "", `checkmark-squared-outlined.svg`],
        legendPosition: "left",
        labels: statusesToDisplay.map((status) => capitalize(status)),
        toolTipLabels: statusesToDisplay.map((status) => capitalize(status)),
        toolTipEnabled: true,
      };
    });

    const keyInformation = view.addComponent({
      component: LargeTextCard,
      layoutProps: {
        xs: 12,
      },
    });

    keyInformation.mapState((state) => {
      return {
        cardTitle: "Management Summary",
        textContent: state.program?.managementSummary ?? "",
        maxHeight: 310,
      };
    });

    for (const dimension of DIMENSIONS) {
      const gaugeCard = view.addComponent({
        component: ProgressBarGaugeCard,
        layoutProps: {
          xs: 3,
        },
      });

      gaugeCard.mapState((state) => {
        const completionDate =
          state.dashboard?.completionDatePerDimension?.[dimension]
            ?.completionDate;

        return {
          title: `Maturity - ${dimension}`,
          showStartDate: false,
          showEndDate: false,
          startDate: "",
          endDate: completionDate ? formatDate(completionDate) : "n/a",
          entries: [
            {
              label: "Maturity",
              isTitle: true,

              percentage:
                (state.dashboard?.maturityLevelPerDimension?.[dimension] ?? 0) *
                50,
              value:
                state.dashboard?.maturityLevelPerDimension?.[dimension] ?? 0,
            },
            ...(state.dashboard?.priorityProgressPerDimension?.perDimension?.[
              dimension
            ]?.perPriority.map((p) => ({
              label: capitalize(
                p.priority === "maturity 1"
                  ? "Mat. Level 1"
                  : p.priority === "maturity 2"
                    ? "Mat. Level 2"
                    : "Mat. Level 3"
              ),
              percentage: p.completionProgress ?? 0,
              value: p.completionProgress ?? 0,
              numerator: p.completedActivities ?? 0,
              denumerator: p.totalNumberOfActivities ?? 0,
            })) ?? []),
          ],
          scale: [0, 1, 2],
        };
      });
    }

    const overallActivitiesM1Title = view.addComponent({
      component: SectionHeader,
      layoutProps: {
        xs: 12,
      },
    });

    overallActivitiesM1Title.mapState(() => {
      return {
        title: `Status of Activities Maturity Level 1`,
        textColor: "#fff",
      };
    });
    for (const dimension of DIMENSIONS) {
      const verticalDonutChartCard = view.addComponent({
        component: DonutVerticalChartCard,
        layoutProps: {
          xs: 3,
        },
      });

      verticalDonutChartCard.mapState((state) => {
        const statusesToDisplay: ActivityStatus[] = [
          "not applicable",
          "not started",
          "at risk",
          "on track",
          "completed",
        ];
        return {
          cardProps: {
            sx: {
              flex: 1,
            },
          },
          title: `${dimension} (total: ${
            state.dashboard?.priorityProgressPerDimension?.perDimension[
              dimension
            ].perPriority.find((p) => p.priority === "maturity 1")
              ?.totalNumberOfActivities ?? 0
          })`,
          series: statusesToDisplay.map(
            (status) =>
              state.dashboard?.activityPerStatusPerOEAndDimension?.[dimension]
                .perMaturity["maturity 1"][status] ?? 0
          ),
          backgroundColor: [
            "#eeeeee",
            "#a3d4eb",
            "#FAB600",
            "#00C853",
            "#003781",
          ],
          legendIcons: ["", "", "", "", `checkmark-squared-outlined.svg`],
          legendPosition: "left",
          labels: statusesToDisplay.map((status) => capitalize(status)),
          toolTipLabels: statusesToDisplay.map((status) => capitalize(status)),
          toolTipEnabled: true,
        };
      });
    }
    const overallActivitiesM2Title = view.addComponent({
      component: SectionHeader,
      layoutProps: {
        xs: 12,
      },
    });

    overallActivitiesM2Title.mapState(() => {
      return {
        title: `Status of Activities Maturity Level 2`,
        textColor: "#fff",
      };
    });
    for (const dimension of DIMENSIONS) {
      const verticalDonutChartCard = view.addComponent({
        component: DonutVerticalChartCard,
        layoutProps: {
          xs: 3,
        },
      });

      verticalDonutChartCard.mapState((state) => {
        const statusesToDisplay: ActivityStatus[] = [
          "not applicable",
          "not started",
          "at risk",
          "on track",
          "completed",
        ];
        return {
          cardProps: {
            sx: {
              flex: 1,
            },
          },
          title: `${dimension} (total: ${
            state.dashboard?.priorityProgressPerDimension?.perDimension[
              dimension
            ].perPriority.find((p) => p.priority === "maturity 2")
              ?.totalNumberOfActivities ?? 0
          })`,
          fallbackText:
            "No activities of maturity level 2 for this dimension specified.",
          series: statusesToDisplay.map(
            (status) =>
              state.dashboard?.activityPerStatusPerOEAndDimension?.[dimension]
                .perMaturity["maturity 2"][status] ?? 0
          ),
          backgroundColor: [
            "#eeeeee",
            "#a3d4eb",
            "#FAB600",
            "#00C853",
            "#003781",
          ],
          legendIcons: ["", "", "", "", `checkmark-squared-outlined.svg`],
          legendPosition: "left",
          labels: statusesToDisplay.map((status) => capitalize(status)),
          toolTipLabels: statusesToDisplay.map((status) => capitalize(status)),
          toolTipEnabled: true,
        };
      });
    }

    const overallActivitiesM3Title = view.addComponent({
      component: SectionHeader,
      layoutProps: {
        xs: 12,
      },
    });

    overallActivitiesM3Title.mapState(() => {
      return {
        title: `Status of Activities Maturity Level 3`,
        textColor: "#fff",
      };
    });
    for (const dimension of DIMENSIONS) {
      const verticalDonutChartCard = view.addComponent({
        component: DonutVerticalChartCard,
        layoutProps: {
          xs: 3,
        },
      });

      verticalDonutChartCard.mapState((state) => {
        const statusesToDisplay: ActivityStatus[] = [
          "not applicable",
          "not started",
          "at risk",
          "on track",
          "completed",
        ];
        return {
          cardProps: {
            sx: {
              flex: 1,
            },
          },
          title: `${dimension} (total: ${
            state.dashboard?.priorityProgressPerDimension?.perDimension[
              dimension
            ].perPriority.find((p) => p.priority === "maturity 3")
              ?.totalNumberOfActivities ?? 0
          })`,
          fallbackText:
            "No activities of maturity level 3 for this dimension specified.",
          series: statusesToDisplay.map(
            (status) =>
              state.dashboard?.activityPerStatusPerOEAndDimension?.[dimension]
                .perMaturity["maturity 3"][status] ?? 0
          ),
          backgroundColor: [
            "#eeeeee",
            "#a3d4eb",
            "#FAB600",
            "#00C853",
            "#003781",
          ],
          legendIcons: ["", "", "", "", `checkmark-squared-outlined.svg`],
          legendPosition: "left",
          labels: statusesToDisplay.map((status) => capitalize(status)),
          toolTipLabels: statusesToDisplay.map((status) => capitalize(status)),
          toolTipEnabled: true,
        };
      });
    }

    const projectInformationTitle = view.addComponent({
      component: SectionHeader,
      layoutProps: {
        xs: 12,
      },
    });

    projectInformationTitle.mapState(() => ({
      title: "General Information",
    }));

    const generalInformation = view.addComponent({
      component: LargeTextCard,
      layoutProps: {
        xs: 12,
      },
    });

    generalInformation.mapState((state) => {
      return {
        cardTitle: "General Information",
        textContent: state.program?.description ?? "",
        maxHeight: 400,
      };
    });
  }
}
