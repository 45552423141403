import { Box, Tab, Tabs } from "@mui/material";
import { SxProps } from "@mui/system";
import { FC, ReactElement, ReactNode, useState } from "react";

export interface TabsWithMountingPanelsProps {
  /** Accessibility Id */
  ariaId?: string;
  /** Panel details */
  panels: TabsWithMountingPanelsPanel[];
  /** if true renders but hides all panels; if false only the current panel is rendered */
  renderHidden?: boolean;
  /** style attributes to be applied to the tab container */
  tabContainerSx?: SxProps;
  /** style attributes to be applied to the tabs */
  tabSx?: SxProps;
}
export interface TabsWithMountingPanelsPanel {
  /** tab text */
  title: string;
  /** panel content */
  content: ReactNode;
  /** Optional icon for tab title */
  icon?: ReactElement;
}

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
  ariaId: string;
  renderHidden: boolean;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ariaId, renderHidden } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`${ariaId}-tabpanel-${index}`}
      aria-labelledby={`${ariaId}-tab-${index}`}
    >
      {(value === index || renderHidden) && children}
    </div>
  );
}

function a11yProps(index: number, ariaId: string) {
  return {
    id: `${ariaId}-tab-${index}`,
    "aria-controls": `${ariaId}-tabpanel-${index}`,
  };
}

/** Tabs with configurable mounting of hidden panels */
export const TabsWithMountingPanels: FC<TabsWithMountingPanelsProps> = ({
  panels,
  ariaId = "default",
  renderHidden = false,
  tabContainerSx = {},
  tabSx = {},
}: TabsWithMountingPanelsProps) => {
  const [value, setValue] = useState(0);

  const handleChange = (newValue: number) => {
    setValue(newValue);
  };
  return (
    <Box sx={{ width: "100%" }}>
      <Tabs
        value={value}
        onChange={(_, value) => handleChange(value as number)}
        variant="scrollable"
        scrollButtons="auto"
        sx={tabContainerSx}
      >
        {panels.map((panel, index) => (
          <Tab
            sx={{ ...tabSx, textTransform: "none" }}
            key={index}
            label={panel.title}
            icon={panel.icon}
            {...a11yProps(index, ariaId)}
          />
        ))}
      </Tabs>
      {panels.map((panel, index) => (
        <TabPanel
          ariaId={ariaId}
          index={index}
          value={value}
          key={index}
          renderHidden={renderHidden}
        >
          {panel.content}
        </TabPanel>
      ))}
    </Box>
  );
};
